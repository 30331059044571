import React from 'react'
import { motion } from 'framer-motion';
import { textanimation } from "../Animation/index";
import backButton from "../../../assets/back.svg"
import Card from './Card';

function index() {
    return (
        <div className="pt-20 bg-white">
            <motion.div variants={textanimation} initial="offscreen" whileInView="onscreen" viewport={{ once: true }}>
                <p className="font-raleway mx-2 sm:mx-0 mb-16 text-3xl md:text-4xl lg:text-5xl xl:text-7xl text-center font-black">
                    Today’s <span className='text-brand' >Best</span> Home Equity Rates
                </p>
            </motion.div>
            <div className='flex flex-col items-center md:flex-row md:justify-center md:gap-10'>
                <Card heading='Home equity line of credit' value={6.25} />
                <Card heading='Home equity loan' value={8.25} />
            </div>
        </div>
    )
}

export default index